import React, { VFC } from 'react';
import { Topnav } from '../../../components/topnav';

interface DsyncRouterProps {}

export const DsyncRouter: VFC<Readonly<DsyncRouterProps>> = () => (
  <>
    <Topnav />
    <div>DSync Router</div>
  </>
);
