import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Intent } from '../../utils/token';
import { intentMapping } from './intent-router';

interface IntentRouteGuardProps {
  intent: Intent;
  guard: Intent;
}

export const IntentRouteGuard: FC<Readonly<IntentRouteGuardProps>> = ({
  intent,
  guard,
  children,
}) => {
  if (intent !== guard) {
    return <Navigate replace to={intentMapping[intent]} />;
  }

  return <>{children}</>;
};
