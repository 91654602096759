import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntentRouter } from '../intent-router';
import { PortalSessionProvider } from '../portal-session-provider';

interface NewAdminPortalWrapperProps {}

const isServer = typeof window === 'undefined';

export const NewAdminPortalWrapper: FC<
  Readonly<NewAdminPortalWrapperProps>
> = ({ children }) => {
  // Temporary hotfix to avoid running Cypress against this new code path. DRGN-76 will migrate Cypress
  // to stop running against Development
  const isLocalDevelopment = false;

  const isNewAdminPortalEnabled =
    useFeatureToggle('adminPortalRevamp') && isLocalDevelopment;

  if (isNewAdminPortalEnabled) {
    return (
      <div suppressHydrationWarning>
        {isServer ? null : (
          <Router>
            <PortalSessionProvider>
              <IntentRouter />
            </PortalSessionProvider>
          </Router>
        )}
      </div>
    );
  }
  return <>{children}</>;
};
