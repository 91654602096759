import is from '@sindresorhus/is';
import { NavigateFunction } from 'react-router-dom';
import { logError } from './logger';

export const handleError = (
  error: unknown,
  navigate: NavigateFunction,
): void => {
  logError(error);

  if (
    is.error(error) &&
    (error.message === 'Invalid token' || error.message === 'Token expired')
  ) {
    return void navigate('/expired');
  }

  return void navigate('/not-found');
};
