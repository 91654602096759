import { GraphQLClient } from 'graphql-request';
import get from 'lodash.get';
import { getSdk, Sdk, SdkFunctionWrapper } from '../../graphql/generated';
import { getCookies } from './get-cookies';
import { logger } from './logger';

export class ForbiddenException {}

const apiUrl = (apiUrl?: string) =>
  process.env.NODE_ENV === 'test'
    ? 'https://www.example.com'
    : apiUrl || process.env.NEXT_PUBLIC_API_URL;

export function graphql(): Sdk {
  const cookies = getCookies();

  const client = new GraphQLClient(`${apiUrl(cookies.apiUrl)}/graphql`, {
    credentials: 'include',
    mode: 'cors',
  });

  client.setHeader('X-CSRF-Token', cookies.csrfToken || '');
  client.setHeader('X-WorkOS-Client', 'Admin Portal');

  const errorHandlingWrapper: SdkFunctionWrapper = async <T>(
    action: () => Promise<T>,
  ): Promise<T> => {
    try {
      return await action();
    } catch (error) {
      const errors = get(error, ['response', 'errors']);

      if (Array.isArray(errors)) {
        for (const e of errors) {
          if (e.extensions?.exception?.response?.statusCode === 403) {
            throw new ForbiddenException();
          }
        }
        logger.error(errors);
      } else {
        logger.error(error as object);
      }

      throw error;
    }
  };

  return getSdk(client, errorHandlingWrapper);
}
