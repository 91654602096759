import pino from 'pino';
import { sendBugsnagError } from './bugsnag';

const isDev = process.env.NEXT_PUBLIC_NODE_ENV === 'development';

export const logger = pino({
  level: 'debug',
  ...(isDev
    ? {
        transport: {
          target: 'pino-pretty',
          options: {
            colorize: true,
          },
        },
      }
    : {}),
});

export const logError = (error: unknown): void => {
  // Likely due to the session expiring
  if (error instanceof Error && error.message.includes('Forbidden resource:')) {
    return;
  }

  const errorString = JSON.stringify(error);

  if (isDev) {
    /* eslint-disable-next-line no-console */
    console.error(error);
  } else {
    sendBugsnagError(error instanceof Error ? error : errorString);
  }
};
