import classnames from 'classnames';
import React, { FC } from 'react';

interface LinkProps {
  href: string;
  appearance?: 'highlight';
  className?: string;
  newTab?: boolean;
  download?: string;
}

export const ExternalLink: FC<Readonly<LinkProps>> = ({
  href,
  children,
  appearance,
  className,
  newTab = false,
  download,
}) => {
  const classNames = classnames(
    'transition duration-200 ease-in-out',
    {
      'text-primary-darken hover:underline dark:text-primary':
        appearance === 'highlight',
    },
    className,
  );

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={classNames}
      download={download}
      href={href}
      rel={newTab ? 'noreferrer noopener' : ''}
      target={newTab ? '_blank' : '_self'}
    >
      {children}
    </a>
  );
};
