import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  InitialPortalQuery,
  PortalSessionFragment,
} from '../../../graphql/generated';
import { setBugsnagSession } from '../../utils/bugsnag';
import { graphql } from '../../utils/graphql';
import { handleError } from '../../utils/handle-errors';
import { validateToken } from '../../utils/token';
import { withTelemetry } from '../../utils/with-telemetry';
import { Spinner } from '../spinner';

interface PortalSessionInitiatorProps {
  initialSession?: PortalSessionFragment;
}

export const PortalSessionContext = React.createContext<
  InitialPortalQuery['portal_portalSession'] | undefined
>(undefined);

export const usePortalSession = () => {
  const session = useContext(PortalSessionContext);
  if (!session) {
    throw new Error(
      'Session value should not be undefined. Please make sure that that this hook was called as a child of PortalSessionProvider',
    );
  }
  return session;
};

export const PortalSessionProvider: FC<
  Readonly<PortalSessionInitiatorProps>
> = ({ children, initialSession }) => {
  const [searchParams] = useSearchParams();

  const startedAt = searchParams.get('started_at');
  const token = searchParams.get('token');

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [session, setSession] = useState<PortalSessionFragment | undefined>(
    initialSession,
  );

  const navigate = useNavigate();

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        if (token) {
          await validateToken(token);
        }

        const response = await withTelemetry({ startedAt, token }, () =>
          graphql().InitialPortal(),
        );

        if (response?.data) {
          const { portal_portalSession: session } = response.data;
          setSession(session);
          setBugsnagSession(session);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!error && !initialSession) {
      void load();
    }
  }, [error, initialSession, startedAt, token]);

  useEffect(() => {
    if (error) {
      handleError(error, navigate);
    }
  }, [error, navigate]);

  if (isLoading && !initialSession) {
    return (
      <div className="h-screen">
        <Spinner isCube />
      </div>
    );
  }

  return (
    <PortalSessionContext.Provider value={session}>
      {children}
    </PortalSessionContext.Provider>
  );
};
